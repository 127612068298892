import api from "@/api";
import { ListOpions } from "@/models/type";
import { parseTime } from "@/utils";
import { Vue } from "vue-class-component";
import OrderDetail from "./detail";
import { h } from 'vue'
import './index.scss'
import { ElMessageBox, ElNotification } from "element-plus";
import OrderComment from "./comment";
export default class MineOrder extends Vue {

  thList: Array<string> = ['商品信息', '收件人', '地址', '实付款', '配送状态']
  orderList: Array<any> = []
  pageNum = 1
  pageSize = 10
  total = 1
  type = ''

  public detailObj: any = {}

  public orderStatusList: ListOpions [] = [
    { name: '待付款', value: 0 },
    { name: '待发货', value: 1 },
    { name: '已发货', value: 2 },
    { name: '已完成', value: 3 },
    { name: '已关闭', value: 4 },
    { name: '无效订单', value: 5 },
    { name: '已评价', value: 6 },
  ]

  orderTable(): JSX.Element {
    return <div class="mine_order-continer">
      <div class="mine_order-continer-th">
        <el-row>
          { this.thList.map((item, index) => <el-col span={index? 4 : 8}>{ item }</el-col>) }
        </el-row>
      </div>
      {this.orderList.length ? this.orderTableTr(): <p style="text-align:center; margin-bottom: 50px">暂无订单</p>}
    </div>
  }

  getList(): void {
    api.orderList({
      pageNum: this.pageNum,
      pageSize: this.pageSize
    }).then(res => {
      this.orderList = res.data.list
      this.total = res.data.total
      this.pageNum = res.data.pageNum
      this.pageSize = res.data.pageSize
    })
  }

  orderTableTr(): JSX.Element {
    return <div class="mine_order-continer-tr">
      { this.orderList.map(item => {
        return <div class="mine_order-continer-tr_cell">
          <div class="mine_order-continer-th">
            <span>{parseTime(new Date(item.createTime).getTime())}</span>
            <span>订单号：{item.orderSn}</span>
          </div>
          <div>
            <el-row>
              <el-col xs={24} sm={24} md={8} lg={8} xl={8} >
                {item.orderItemList && item.orderItemList.map((i: any): JSX.Element => <div
                  onClick={() => this.goDetail(i.productId)}
                >
                  <img src={i?.productPic} alt="" />
                  <p class="tr_imgtext tr_jq">{i?.productName}</p>
                </div>)}
              </el-col>
              <el-col xs={24} sm={12} md={4} lg={4} xl={4} >
                <p class="tr_text">{item.receiverName}</p>
                <p class="tr_text">{item.receiverPhone}</p>
              </el-col>
              <el-col xs={24} sm={12} md={4} lg={4} xl={4} >
                <p class="tr_text">{item.receiverProvince} {item.receiverCity} {item.receiverRegion} {item.receiverDetailAddress}</p>
              </el-col>
              <el-col xs={12} sm={12} md={4} lg={4} xl={4} >
                <p class="tr_text">{item.payType===5 ? '积分：' : 'HK$：'} <span class="tr_jq">{item.payAmount}</span></p>
              </el-col>
              <el-col xs={12} sm={12} md={4} lg={4} xl={4} >
                <p class="tr_text">{this.orderStatusList.find(Item => Item.value === item?.status)?.name}</p>
              </el-col>
            </el-row>
          </div>
          <div class="tr_btn">
            <div class="tr_btn-l pointer" onClick={() => this.getDetail(item)}>
              {item.loading  && <i class="el-icon-loading" style="margin-right: 10px"></i>}
              <span>订单详情</span>
            </div>
            {(() => {
              switch (item.status) {
                case 0:
                  return  <div class="tr_btn-r pointer" onClick={() => this.toPay(item.id)}>去支付</div>
                case 2:
                  return <div class="tr_btn-r pointer" onClick={() => this.confirmReceiveOrder(item.id)}>确认收货</div>
                case 3:
                  return <div class="tr_btn-r pointer" onClick={() => this.comment(item)}>评论</div>
                case 6:
                  return <div class="tr_btn-r pointer" onClick={() => this.comment(item)}>查看评论</div>
                default:
              }
            })()}
          </div>
        </div>
      }) }
    </div>
  }

  /**
   * @description 去支付
   * @author Jinx
   * @date 2021-10-28 09:37:43
   * @param {number} order_id
   * @memberof MineOrder
   */
  toPay(order_id: number): void {
    this.$router.push({
      name: 'SanchuangPay',
      query: {
        order_id
      }
    })
  }

  /**
   * @description 确认收货
   * @author Jinx
   * @date 2021-10-27 18:29:07
   * @param {number} id
   * @memberof MineOrder
   */
  confirmReceiveOrder(orderId: number): void {
    ElMessageBox.confirm('确认收货？', '收货', {
      type: 'warning'
    }).then(() => {
      api.confirmReceiveOrder(orderId).then(() => {
        ElNotification({
          type: 'success',
          title: '成功',
          message: '收获成功'
        })
        this.getList()
      })
    }).catch(() => false)
  }

  comment(row: any): void {
    this.detailObj = Object.assign({}, row)
    this.type = 'comment'
  }

  getDetail(row: any): void {
    if(row.laoding) {
      return
    }
    row.loading = true
    api.orderDetail(row.id).then(res => {
      this.detailObj = res.data
      row.loading = false
      this.type = 'detail'
    }).catch(() => {
      row.loading = false
    })
  }

  goDetail(id: number): void {
    if(document.body.clientWidth < 900) {
      // TODO 跳转的mobile
      window.location.href = `http://mobile.taosseract.com/pages/mall/detail?id=${id}`
    } else {
      this.$router.push({
        name: 'SanchuangDetail',
        params: {
          id
        },
        query: {
          type: 0
        }
      })
    }
  }

  handleCurrentChange(pageNum: number): void {
    this.pageNum = pageNum
    this.getList()
  }

  render(): JSX.Element {
    return (() => {
      switch (this.type) {
        case 'detail':
          return h(OrderDetail, {
            detailObj: this.detailObj
          })
        case 'comment':
          return h(OrderComment, {
            detailObj: this.detailObj
          })
        default:
          return <div class="mine_order">
          {this.orderTable()}
          <el-pagination
            class="mine_order-pagination"
            onCurrentChange={this.handleCurrentChange}
            currentPage={this.pageNum}
            pageSize={this.pageSize}
            layout="prev, pager, next, total"
            total={ this.total }
          />
        </div>
      }
    })()
  }

  mounted(): void {
    this.getList()
  }
}
