import { TableRow } from "@/models/type";
import { Prop, Vue, Watch } from "vue-property-decorator";
import MineOrder from ".";


export default class OrderDetail extends Vue {

  @Prop({ type: Object, default: () => ({}) }) readonly detailObj?: any

  goBack(): void {
    const __parent = this.$parent as MineOrder
    __parent.type = ''
  }

  detailList: any = []

  public payList: any[] = [
    {title: '微信支付', value: 2},
    {title: '支付宝支付', value: 1},
    // {title: 'PayMe支付', value: 5},
    {title: '积分支付', value: 5},
    {title: 'VISA支付', value: 3},
    {title: 'Master支付', value: 4},
  ]

  @Watch('detailObj', { deep: true, immediate: true })
  getDetail(val: any): void {
    this.detailList = [
      {
        title: '基本信息',
        tableData: [val],
        tableList: [
          { label: '订单编号', prop: 'orderSn' },
          { label: '下单账号', prop: 'memberUsername' },
          { label: '支付方式', slot: {
            default: (scope: any): JSX.Element => <span>{this.payList.find(item => item.value === scope.row.payType)?.title}</span>
          } },
          { label: '物流公司', prop: 'deliveryCompany' },
          { label: '物流单号', prop: 'deliverySn' },
          { label: '自动收货时间', slot: {
            default: (scope: any): JSX.Element => <span>{scope.row.autoConfirmDay}天</span>
          } },
        ] as TableRow[]
      },
      {
        title: '收件人信息',
        tableData: [val],
        tableList: [
          { label: '收件人', prop: 'receiverName' },
          { label: '手机号', prop: 'receiverPhone' },
          { label: '地址' , slot: {
            default: (scope: any): JSX.Element => <span>{scope.row.receiverProvince} {scope.row.receiverCity} {scope.row.receiverRegion} {scope.row.receiverDetailAddress}</span>
          } },
          { label: '邮编', prop: 'receiverPostCode' },
        ] as TableRow[]
      },
      {
        title: '商品信息',
        tableData: val?.orderItemList,
        tableList: [
          { label: '商品详情', slot: {
            default: (scope: any): JSX.Element => <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {scope.row.productPic && <img
                src={scope.row.productPic}
                style={{
                  width: '50px',
                  marginRight: '10px'
                }}
                alt=""
              />}
              <span>{scope.row.productName}</span>
            </div>
          } },
          { label: '商品编号', prop: 'id' },
          { label: '商品货号', prop: 'productSkuCode' },
          { label: '价格', prop: 'productPrice' },
          { label: '数量', prop: 'productQuantity' },
        ] as TableRow[]
      },
      {
        title: '费用信息',
        tableData: [val],
        tableList: [
          { label: '商品合计', slot: {
            default: (scope: any): JSX.Element => <span>{scope.row.totalAmount || 0} {scope.row.orderType ? '积分' : 'HK$'}</span>
          } },
          { label: '运费', slot: {
            default: (scope: any): JSX.Element => <span>{scope.row.freightAmount || 0}</span>
          } },
          { label: '代金券', slot: {
            default: (scope: any): JSX.Element => <span>-{scope.row.couponAmount}</span>
          } },
          { label: '实付款', slot: {
            default: (scope: any): JSX.Element => <span>{scope.row.payAmount} {scope.row.orderType ? '积分' : 'HK$'}</span>
          } }
        ] as TableRow[]
      }
    ]
  }

  render(): JSX.Element {
    return <div class="mine_order_detail">
      <div class="mine_order_detail-header">
        <div onClick={this.goBack}>
          <i class="el-icon-arrow-left"/>
          <span>返回</span>
        </div>
      </div>
      {this.detailList.map((itme: any) => <>
        <div class="mine_order_detail-title">{itme.title}</div>
          <div class="mine_order_detail-content">
            <el-table
              data={itme.tableData}
            >
              {itme.tableList.map((i: any): JSX.Element => <el-table-column
                label={i.label}
                prop={i.prop}
                align="center"
                v-slots={i.slot}
              />)}
            </el-table>
          </div>
      </>)}
    </div>
  }
}
