import api from "@/api";
import { CommentParam } from "@/api/modules/comment";
import { ElNotification } from "element-plus";
import { Vue, Prop, Watch } from "vue-property-decorator";
import MineOrder from ".";

export default class OrderComment extends Vue {

  @Prop({ type: Object, default: () => ({}) }) readonly detailObj?: any

  // 给个对象
  contentValue: any = {

  }

  public dialogVisible = false
  public dialogImageUrl = ''
  public fileList: any = {}

  goBack(): void {
    const __parent = this.$parent as MineOrder
    __parent.type = ''
  }

  @Watch('detailObj', {deep: true, immediate: true})
  detailObjChange(val: any): void {
    if(val) {
      const orderId = val.id
      console.log(orderId)
      api.getComments({
        orderId,
        isMine: 1
      }).then(res => {
        const list = res.data.list
        list.forEach((item: any, index: number) => {
          this.contentValue[`content_${index}`] = item.content
          this.fileList[`fileList_${index}`] = []
          item.pics?.split(',').forEach((i: string) => {
            this.fileList[`fileList_${index}`].push({
              type: 'add',
              url: i
            })
          })
        })
      })
    }
  }

  handlePictureCardPreview(file: any): void {
    this.dialogImageUrl = file.type === 'add' ? file.url : file.response.data
    this.dialogVisible = true
  }
  submit(): void {
    const submitArr: CommentParam [] = []
    this.detailObj?.orderItemList?.forEach((item: any, index: number) => {
      const __upload = this.$refs[`upload_${index}`] as any
      const arr: string[] = []
      __upload.uploadFiles.forEach((item: any) => {
        arr.push(item.type === 'add' ? item.url : item.response.data)
      })
      const obj: CommentParam = {}
      obj.content = this.contentValue[`content_${index}`]
      obj.pics = arr.join(',')
      obj.productId = item.productId
      obj.productSn = item.productSn
      submitArr.push(obj)
    })
    api.commentComment(submitArr, this.detailObj.id).then(res => {
      ElNotification({
        type: 'success',
        title: '成功',
        message: '评论成功'
      })
      this.goBack()
    })
  }

  render(): JSX.Element {
    return <div class="mine_order_detail">
      <el-dialog v-model={this.dialogVisible}>
        <img
          src={this.dialogImageUrl}
          style={{
            width: '100%'
          }}
          alt=""
        />
      </el-dialog>
      <div class="mine_order_detail-header">
        <div onClick={this.goBack}>
          <i class="el-icon-arrow-left"/>
          <span>返回</span>
        </div>
      </div>
      <div class="mine_order_detail-comment">
        <div class="comment-header">发表评论</div>
        {this.detailObj?.orderItemList?.map((item: any, index: number): JSX.Element => <div>
          <el-row
            style="margin-bottom: 20px"
            gutter={10}
          >
            <el-col xs={24} sm={12} md={8} lg={6} xl={6}>
              <div class="comment-left">
                <div class="comment-left-info">
                  <img src={item.productPic} alt="" />
                  <p>{ item.productName }</p>
                </div>
                <div class="comment-left-price">
                  HK$ <span>{ item.realAmount }</span>
                </div>
              </div>
            </el-col>
            <el-col xs={24} sm={12} md={16} lg={18} xl={18}>
              <div class="comment-right">
                <div class="comment-right-title">请写下你的评论</div>
                <el-input
                  type="textarea"
                  v-model={this.contentValue[`content_${index}`]}
                  style="width: 100%; margin: 40px 0"
                  placeholder="写下你的感受吧！"
                  row={4}
                  show-word-limit maxlength={150}
                />
                <el-upload
                  ref={`upload_${index}`}
                  size="mini"
                  action="/mall-portal/common/ossUpload"
                  list-type="picture-card"
                  file-list={this.fileList[`fileList_${index}`]}
                  on-preview={this.handlePictureCardPreview}
                >
                  <i class="el-icon-plus" />
                </el-upload>
              </div>
            </el-col>
          </el-row>
        </div>)}
        <el-row>
          <div class="comment-bottom">
            <div onClick={this.submit}>发表</div>
          </div>
        </el-row>
      </div>
    </div>
  }
}
